<template>
  <section>
    <b-modal
      :no-close-on-backdrop="true"
      ref="applicationStatusModal"
      @hidden="onApplicationStatusModalClose"
      hide-footer
      size="lg"
      title="Status Description"
    >
      <div v-if="currentApplicationStatus" class="p-2">
        <h3 class="mb-1">{{ currentApplicationStatus.status_name }}</h3>
        <p>
          {{ currentApplicationStatus.status_description }}
        </p>
      </div>
    </b-modal>

    <b-modal
      :no-close-on-backdrop="true"
      ref="universityPaymentModal"
      no-close-on-esc
      no-close-on-backdrop
      centered
      @hidden="onUniversityPaymentModalClose"
      hide-footer
      size="lg"
      title="Add University Payment"
    >
      <b-row style="gap: 20px 0">
        <b-col cols="12">
          <label>Fee Type</label>
          <v-select
            v-model="university_payment_form.fee_type"
            :options="fee_type_options"
            label="label"
            :reduce="(item) => item.value"
            placeholder="Fee Type"
          ></v-select>
        </b-col>
        <b-col cols="12">
          <label>University</label>
          <v-select
            v-model="university_payment_form.fw_university_id"
            :options="flyware_universities"
            label="name"
            :reduce="(item) => item.id"
            placeholder="University"
            @input="onUniversitySelect"
          ></v-select>
        </b-col>

        <b-col cols="4">
          <label>Currency</label>
          <b-form-input disabled v-model="university_payment_form.currency" />
        </b-col>

        <b-col cols="8">
          <label>Amount</label>
          <b-form-input
            type="number"
            v-model="university_payment_form.amount"
          />
        </b-col>

        <b-col cols="12">
          <label>Remark</label>
          <b-form-input v-model="university_payment_form.label" />
        </b-col>

        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="primary" @click="makeUniversityPayment"
            >Create Payment</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <b-card>
      <div>
        <b-row cols="12" class="mb-1">
          <b-col cols="9">
            <b-form-input
              placeholder="Search"
              v-model="filters.searchText"
              @input="onSearch"
            ></b-form-input>
          </b-col>
          <b-col cols="3">
            <v-select
              cols="4"
              v-model="filters.selectedStatus"
              :options="application_statuses"
              label="status_name"
              :reduce="(item) => item.status_id"
              placeholder="Status"
              class="mr-1"
              @input="onSearch"
            ></v-select>
          </b-col>
        </b-row>

        <b-row cols="12" class="mb-1">
          <b-col cols="5">
            <b-row cols="12">
              <b-col cols="6">
                <v-select
                  v-model="filters.intakeDate.month"
                  :options="all_months"
                  placeholder="Intake Month"
                  @input="onSearch"
                />
              </b-col>
              <b-col cols="6">
                <v-select
                  v-model="filters.intakeDate.year"
                  :options="getAllYears()"
                  class=""
                  placeholder="Intake Year"
                  @input="onSearch"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="7">
            <b-row cols="12">
              <b-col>
                <flat-pickr
                  v-model="filters.selectedApplicationDate"
                  placeholder="Application Date Range"
                  class="form-control"
                  :config="{ mode: 'range' }"
                  @input="onSearch"
                />
              </b-col>
              <b-col>
                <v-select
                  v-model="filters.selectedCountry"
                  :reduce="(country) => country.country_id"
                  label="country_name"
                  :options="this.$store.state.home.Countries"
                  placeholder="Country"
                  @input="onSearch"
                ></v-select>
              </b-col>
              <b-col>
                <!-- <flat-pickr placeholder="Follow Up Date & Time" v-model="filters.follow_up_date" @input="onSearch"
                        class="form-control" :config="{
                          // enableTime: true,
                          dateFormat: 'Y-m-d',
                          altInput: true,
                          altFormat: 'd-m-Y',
                          allowInput: true,
                        }" /> -->
                <flat-pickr
                  id="assigned_on"
                  placeholder="Select Date Range"
                  v-model="filters.follow_up_date"
                  class="form-control"
                  @input="onSearch"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'd-m-Y',
                    allowInput: true,
                    mode: 'range',
                  }"
                />
              </b-col>
              <b-col v-if="['visa', 'operations'].includes(user_type)">
                <v-select
                  v-model="filters.counselor_id"
                  :options="
                    counselor_options.map((counselor) => ({
                      value: counselor.user_id,
                      label: `${counselor.user_firstname} ${counselor.user_lastname}`,
                    }))
                  "
                  placeholder="Counselor"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  class="per-page-selector d-inline-block w-100"
                  @input="onSearch"
                />
              </b-col>
              <b-col>
                <b-row cols="12" class="pr-1">
                  <!-- <b-col cols="6">
                    <button
                      class="btn btn-primary mr-1 w-100"
                      @click="getApplications()"
                    >
                      Search
                    </button>
                  </b-col> -->
                  <b-col cols="12 ">
                    <button
                      class="btn btn-primary w-100"
                      @click="resetFilters()"
                    >
                      Reset
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <b-table
        id="StudentsApplicationsTableId"
        ref="refStudentsApplicationsTable"
        class="position-relative"
        :items="applicationsList"
        responsive
        :fields="tableColumns"
        primary-key="sa_mapper_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- hide show -->
        <template #cell(showdetails)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="
              (e) => {
                row.toggleDetails(e);
                applicationDetails(row.item);
              }
            "
          />
        </template>

        <template #row-details="row">
          <b-card class="border p-2">
            <b-tabs>
              <b-tab title="Application Details">
                <b-row cols="12">
                  <b-col
                    cols="3"
                    class="
                      d-flex
                      flex-column
                      py-5
                      align-items-start
                      px-5
                      border
                      rounded-lg
                    "
                  >
                    <div class="w-100 mb-2">
                      <div>
                        <h6>Application Status</h6>
                        <div class="d-flex w-100" style="gap: 10px">
                          <b-form-select
                            style="width: 330px"
                            :disabled="
                              !['operations', 'visa'].includes(user_type)
                            "
                            v-model="row.item.status_id"
                            @input="row.item.sub_status_id = null"
                          >
                            <b-form-select-option
                              v-for="(item, index) in application_statuses"
                              :key="'statuses_ ' + index"
                              :value="item.status_id"
                            >
                              {{ item.status_name }}
                            </b-form-select-option>
                          </b-form-select>
                          <button class="btn btn-sm">
                            <feather-icon
                              style="cursor: pointer"
                              icon="InfoIcon"
                              size="20"
                              @click="
                                showApplicationStatusModal(row.item.status_id)
                              "
                            />
                          </button>
                        </div>
                      </div>

                      <div class="mt-2">
                        <h6>Application Sub Status</h6>
                        <div class="d-flex w-100" style="gap: 10px">
                          <b-form-select
                            style="width: 330px"
                            v-if="applicationHasSubStatus(row.item.status_id)"
                            :disabled="
                              !['operations', 'visa'].includes(user_type)
                            "
                            v-model="row.item.sub_status_id"
                          >
                            <b-form-select-option
                              v-for="(
                                item, index
                              ) in getFilteredApplicationSubStatuses(
                                row.item.status_id
                              )"
                              :key="'statuses_ ' + index"
                              :value="item.sub_status_id"
                            >
                              {{ item.sub_status_name }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 mb-2 d-flex">
                      <div class="mr-2">
                        <h6>Application Fees</h6>
                        <b-form-input
                          style="width: auto"
                          :disabled="
                            !['operations', 'visa'].includes(user_type)
                          "
                          :value="row.item.application_fee"
                          @input="row.item.application_fee = $event"
                        />
                      </div>

                      <div>
                        <h6>Currency</h6>
                        <b-form-select
                          style="width: auto"
                          :disabled="
                            !['operations', 'visa'].includes(user_type)
                          "
                          v-model="row.item.fee_currency"
                        >
                          <b-form-select-option
                            v-for="(item, index) in currency_codes"
                            :key="'currency_ ' + index"
                            :value="item.code"
                          >
                            {{ item.code }}
                          </b-form-select-option>
                        </b-form-select>
                      </div>
                    </div>
                    <div class="w-100 mb-3">
                      <h6>Comments</h6>
                      <b-form-textarea
                        class="h-100"
                        style="width: 330px"
                        :disabled="!['operations', 'visa'].includes(user_type)"
                        :value="row.item.comments"
                        @input="row.item.comments = $event"
                      />
                    </div>

                    <div class="w-100 mb-1">
                      <h6>Tags</h6>
                      <b-form-textarea
                        style="width: 330px"
                        :disabled="!['operations', 'visa'].includes(user_type)"
                        :value="row.item.tags"
                        @input="row.item.tags = $event"
                      />
                    </div>

                    <div class="w-100 mb-2 pr-5">
                      <h6>Deposit Amount</h6>
                      <b-form-input
                        style="max-width: 100%"
                        :disabled="!['operations', 'visa'].includes(user_type)"
                        :value="row.item.deposit_amount"
                        @input="row.item.deposit_amount = $event"
                      />
                    </div>

                    <div class="w-100 mb-2 pr-5">
                      <h6>Deadline Date</h6>
                      <flat-pickr
                        :disabled="!['operations', 'visa'].includes(user_type)"
                        placeholder="Select Deadline Date & Time"
                        v-model="row.item.payment_deadline_date"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          allowInput: true,
                        }"
                      />
                    </div>

                    <div class="w-100 mb-2 pr-5">
                      <h6>Follow Up Date</h6>
                      <flat-pickr
                        :disabled="!['operations', 'visa'].includes(user_type)"
                        placeholder="Select Follow Up Date & Time"
                        v-model="row.item.follow_up_date"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          allowInput: true,
                        }"
                      />
                    </div>

                    <!-- <div class="w-100 mb-4">
                      <h6>Remarks</h6>
                      <b-form-textarea class="h-100" style="width: 330px"
                        :disabled="!['operations', 'visa'].includes(user_type)" :value="row.item.comment"
                        @input="row.item.comment = $event" />
                    </div> -->

                    <div
                      v-if="['operations', 'visa'].includes(user_type)"
                      class="d-flex"
                      style="gap: 2px; width: 330px"
                    >
                      <button
                        class="btn btn-primary w-100 text-nowrap"
                        @click="
                          navigateToStudentDocument(row.item.student_user_id)
                        "
                      >
                        Add Document
                      </button>

                      <button
                        class="btn btn-primary w-100"
                        @click="() => updateApplication(row.item)"
                      >
                        Save
                      </button>
                    </div>
                  </b-col>
                  <b-col
                    cols="5"
                    class="border p-5 rounded-lg ml-1"
                    style="max-height: 895px; overflow-y: auto"
                  >
                    <b-card>
                      <timeline-basic
                        :applicationTimeline="
                          applicationTimeline[row.item.sa_mapper_id]
                        "
                      />
                    </b-card>

                    <!-- <b-button size="sm" variant="outline-secondary" class="mt-2" @click="row.toggleDetails">
                      Hide Details
                    </b-button> -->
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="University Payments">
                <div style="width: fit-content">
                  <div
                    class="d-flex justify-content-end mb-1 w-100"
                    v-if="['operations', 'visa'].includes(user_type)"
                  >
                    <b-button
                      variant="primary"
                      @click="
                        () => showUniversityPaymentModal(row.item.sa_mapper_id)
                      "
                      >Add Payment</b-button
                    >
                  </div>
                  <b-table
                    id="StudentsApplicationsTableId"
                    ref="refStudentsApplicationsTable"
                    class="position-relative border rounded-lg"
                    :items="row.item.university_payments"
                    responsive
                    :fields="universityPaymentTableColumns"
                    primary-key="sa_mapper_id"
                    show-empty
                    empty-text="No matching records found"
                  >
                    <template #cell(No)="data">
                      {{ row.item.university_payments.indexOf(data.item) + 1 }}
                    </template>

                    <template #cell(fee_type)="data">
                      {{
                        fee_type_options.find(
                          (up) => up.value === data.item.fee_type
                        ) &&
                        fee_type_options.find(
                          (up) => up.value === data.item.fee_type
                        ).label
                      }}
                    </template>

                    <template #cell(label)="data">
                      <div style="width: max-content">
                        <p
                          :style="
                            data.item.is_active === 'N' &&
                            'text-decoration: line-through'
                          "
                        >
                          {{ data.item.label }}
                        </p>
                      </div>
                    </template>

                    <template #cell(fw_university_name)="data">
                      <div style="width: max-content">
                        <p
                          :style="
                            data.item.is_active === 'N' &&
                            'text-decoration: line-through'
                          "
                        >
                          {{ data.item.fw_university_name }}
                        </p>
                      </div>
                    </template>

                    <template #cell(currency)="data">
                      <div style="width: max-content">
                        <p
                          :style="
                            data.item.is_active === 'N' &&
                            'text-decoration: line-through'
                          "
                        >
                          {{ data.item.currency }}
                        </p>
                      </div>
                    </template>

                    <template #cell(amount)="data">
                      <div style="width: max-content">
                        <p
                          :style="
                            data.item.is_active === 'N' &&
                            'text-decoration: line-through'
                          "
                        >
                          {{ data.item.amount }}
                        </p>
                      </div>
                    </template>

                    <template #cell(payment_status)="data">
                      <div style="width: max-content">
                        <b-badge
                          pill
                          :variant="
                            payment_status_map[data.item.payment_status].variant
                          "
                          v-if="data.item.is_active === 'Y'"
                        >
                          {{
                            payment_status_map[data.item.payment_status].label
                          }}
                        </b-badge>
                      </div>
                    </template>

                    <template #cell(actions)="data">
                      <div
                        style="width: max-content"
                        v-if="['operations', 'visa'].includes(user_type)"
                      >
                        <p
                          v-if="
                            data.item.is_active === 'Y' &&
                            (data.item.payment_status === 'pending' ||
                              data.item.payment_status === 'failed')
                          "
                          class="text-danger cursor-pointer"
                          @click="() => cancelPayment(data.item.up_id)"
                        >
                          Cancel
                        </p>

                        <b-badge
                          v-if="data.item.is_active === 'N'"
                          pill
                          variant="danger"
                          >Cancelled</b-badge
                        >
                      </div>

                      <div v-else>
                        <b-badge
                          pill
                          variant="danger"
                          v-if="data.item.is_active === 'N'"
                          >Cancelled</b-badge
                        >
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </template>

        <template #cell(course_fee_pa)="row">
          <div style="width: max-content">
            {{ row.value.toLocaleString("en-IN") }}
            {{ row.item.currency }}
          </div>
        </template>

        <!-- <template #cell(action)="row">
          <div style="width: max-content">
            <button
              class="btn btn-primary"
              @click="navigateToStudentDocument(row.item.student_user_id)"
            >
              Add Document
            </button>
          </div>
        </template> -->

        <template #cell(application_fee)="row">
          <div style="width: max-content">
            {{ row.value.toLocaleString("en-IN") }}
            {{ row.item.fee_currency }}
          </div>
        </template>

        <template #cell(course_year)="row">
          <div style="width: max-content">
            <div v-if="row.value">{{ row.value }} Year(s)</div>
            <div v-if="row.item.course_month" style="margin-top: 10px">
              {{ row.value }} Month(s)
            </div>
          </div>
        </template>
        <!-- <template #cell(index)="data">
          {{
            data.index + pagination.perPage * (pagination.currentPage - 1) + 1
          }}
        </template> -->
        <template #cell(course_name)="data">
          <div>
            <h5 class="m-0 p-0" style="width: max-content">
              {{ data.item.course_name }}
            </h5>
            <!-- <br /> -->
            <p
              class="m-0 p-0 text-primary"
              style="cursor: pointer"
              @click="goToUniversityPage(data.item.university_id)"
            >
              {{ data.item.university_name }}
            </p>
          </div>
          <div class="d-flex align-items-center">
            <!-- {{ data.value }} -->
            <!-- External Link Icon -->
            <a
              :href="data.item.additional_information_link"
              style="margin-right: 5px; margin-left: 10px"
              target="_blank"
            >
              <b-avatar variant="light-danger" size="30">
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="15"
                  class="text-danger"
                />
              </b-avatar>
            </a>
            <div>
              <a
                @click="() => openDetailModal(data.item)"
                style="margin-right: 3px"
              >
                <b-avatar variant="light-danger" size="30">
                  <feather-icon
                    icon="InfoIcon"
                    size="15"
                    class="text-danger"
                  /> </b-avatar
              ></a>
            </div>
            <div>
              <b-badge
                :variant="data.item.is_direct === 'Y' ? 'success' : 'danger'"
                class="ml-2"
              >
                {{ data.item.is_direct === "Y" ? "Direct" : "Indirect" }}
              </b-badge>
            </div>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"> {{ totalApplications }} entries </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              aria-controls="StudentsListTableId"
              v-model="pagination.currentPage"
              :total-rows="totalApplications"
              :per-page="pagination.perPage"
              @change="onChange"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        :no-close-on-backdrop="true"
        ref="detailModal"
        @hidden="onDetailModalClose"
        hide-footer
        size="xl"
      >
        <div class="custom-modal-content">
          <div class="row">
            <!-- GENERAL ELIGIBILITY -->
            <div class="mb-2 col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">
                General Eligibility
              </div>
              <div>
                {{
                  popupData && popupData.general_eligibility
                    ? popupData.general_eligibility
                    : "NA"
                }}
              </div>
            </div>
            <!-- LANGUAGE REQUIREMENT -->
            <div class="mb-2 col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">
                Language Requirement
              </div>
              <!-- <div>{{ languages_requirement }}</div> -->
              <div>
                {{
                  popupData && popupData.languages_requirement
                    ? popupData.languages_requirement
                    : "NA"
                }}
              </div>
            </div>
            <!-- ADDITIONAL INFORMATION -->

            <!-- INTAKES -->
            <div class="mb-2 mb-md-0 col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">Intake</div>
              <div>
                <span class="mr-1">Intake Start:</span>
                <span>{{
                  popupData && popupData.intake ? popupData.intake : "NA"
                }}</span>
              </div>
              <div class="my-2">
                <div>
                  <span class="mr-1">Intake Start:</span
                  ><span>{{
                    popupData && popupData.intake_start
                      ? popupData.intake_start
                      : "NA"
                  }}</span>
                </div>
                <div>
                  <span class="mr-1">Intake End:</span
                  ><span>{{
                    popupData && popupData.intake_end
                      ? popupData.intake_end
                      : "NA"
                  }}</span>
                </div>
              </div>

              <div>
                <span class="mr-1">Intake Deadline 1:</span
                ><span>{{
                  popupData && popupData.intake_deadline_1
                    ? popupData.intake_deadline_1
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake Deadline 2:</span
                ><span>{{
                  popupData && popupData.intake_deadline_2
                    ? popupData.intake_deadline_2
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake Deadline 3:</span
                ><span>{{
                  popupData && popupData.intake_deadline_3
                    ? popupData.intake_deadline_3
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake Deadline 4:</span
                ><span>{{
                  popupData && popupData.intake_deadline_4
                    ? popupData.intake_deadline_4
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake Deadline 5:</span
                ><span>{{
                  popupData && popupData.intake_deadline_5
                    ? popupData.intake_deadline_5
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake Deadline 6:</span
                ><span>{{
                  popupData && popupData.intake_deadline_6
                    ? popupData.intake_deadline_6
                    : "NA"
                }}</span>
              </div>
            </div>

            <!-- INTERNATIONAL SCHOLARSHIP DESCRIPTION -->
            <div class="col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">
                International Scholarship Description
              </div>
              <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->
              <div>
                <p>
                  {{
                    popupData && popupData.internation_scholarship_description
                      ? popupData.internation_scholarship_description
                      : "NA"
                  }}
                </p>
              </div>

              <div class="fs-14">
                <span class="font-bold mr-1">Scholarship Page Link:</span
                ><a href="">{{
                  popupData && popupData.scholarship_page_link
                    ? popupData.scholarship_page_link
                    : "NA"
                }}</a>
              </div>
            </div>
            <!-- GRE -->
            <div class="mb-2 col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">Other</div>

              <div>
                <span class="mr-1">GRE Requirement:</span
                ><span>{{
                  popupData && popupData.gre_requirement
                    ? popupData.gre_requirement
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">GRE Score:</span
                ><span>{{
                  popupData && popupData.gre_score ? popupData.gre_score : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">SAT Requirement:</span
                ><span>{{
                  popupData && popupData.sat_requirement
                    ? popupData.sat_requirement
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">SAT Score:</span
                ><span>{{
                  popupData && popupData.sat_score ? popupData.sat_score : "NA"
                }}</span>
              </div>
            </div>
            <!-- add -->
            <div class="col-12 col-md-4">
              <div class="course-detail__sm-title mb-1">
                Additional Information
              </div>
              <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->
              <div class="fs-14">
                <span class="font-bold mr-1">Stem Course:</span
                ><span>{{
                  popupData && popupData.stem_course
                    ? popupData.stem_course
                    : "NA"
                }}</span>
              </div>
              <div class="fs-14">
                <span class="font-bold mr-1"
                  >Fifteen Years Education Acceptable:</span
                ><span>{{
                  popupData && popupData.fifteen_years_education_acceptable
                    ? popupData.fifteen_years_education_acceptable
                    : "NA"
                }}</span>
              </div>
              <div class="fs-14">
                <span class="font-bold mr-1">IELTS Waiver:</span
                ><span>{{
                  popupData && popupData.ielts_waiver
                    ? popupData.ielts_waiver
                    : "NA"
                }}</span>
              </div>
              <div class="fs-14">
                {{
                  popupData && popupData.if_ielts_is_waived_conditions_for
                    ? popupData.if_ielts_is_waived_conditions_for
                    : "NA"
                }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormGroup,
  BFormCheckbox,
  BFormSelectOption,
  BAvatar,
  BTab,
  BTabs,
  BBadge,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import CommonServices from "@/apiServices/CommonServices";
import OperationsServices from "@/apiServices/OperationsServices";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import TimelineBasic from "./timeline/TimelineBasic.vue";
import { all_months, getAllYears } from "@/assets/json_data/data";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import currency_codes from "currency-codes";
import router from "@/router";
import { BASEURL } from "@/config";
import moment from "moment";

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BFormSelect,
    vSelect,
    flatPickr,
    BFormGroup,
    BFormCheckbox,
    TimelineBasic,
    BFormSelectOption,
    BFormTextarea,
    BAvatar,
    FeatherIcon,
    BTab,
    BTabs,
    BBadge,
    // AppTimeline,
    // AppTimelineItem,
  },
  data() {
    return {
      applicationsList: [],
      tableColumns: [
        { key: "showdetails", label: "show" },
        { key: "sa_mapper_id", label: "Application Id" },
        { key: "student_user_id", label: "Student Id" },
        { key: "user_name", label: "User Name" },
        { key: "course_name", label: "Course / University" },
        { key: "course_fee_pa", label: "Fee (P.A.)" },
        { key: "sa_campus_location", label: "Campus Location" },
        { key: "application_fee", label: "Application Fee" },
        { key: "course_year", label: "Duration" },
        { key: "status_name", label: "Status" },
        { key: "intake", label: "Intake" },
        { key: "applied_on", label: "Application Date" },
        { key: "country_name", label: "Country" },
        // { key: "action", label: "Actions" },
      ],

      universityPaymentTableColumns: [
        { key: "No", label: "#" },
        { key: "fee_type", label: "Fee Type" },
        { key: "fw_university_name", label: "University" },
        { key: "currency", label: "Currency" },
        { key: "amount", label: "Amount" },
        { key: "label", label: "Remark" },
        { key: "payment_status", label: "Payment Status" },
        { key: "actions", label: "Actions" },
      ],

      totalApplications: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
      },
      expandedRows: [], // Store the expanded state of each row
      expandedModal: null, // Used for modal expansion

      config: {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d-m-Y",
        allowInput: true,
      },

      filters: {
        searchText: "",
        selectedStatus: null,
        selectedApplicationDate: null,
        selectedCountry: null,
        counselor_id: null,
        follow_up_date: null,
        intakeDate: {
          month: "",
          year: "",
        },
      },

      statusFilterOptions: [],
      counselor_options: [],
      all_months,

      applicationTimeline: {},
      application_statuses: [],
      application_sub_statuses: [],
      currentApplicationStatus: null,
      currency_codes: currency_codes.data,
      popupData: {},
      debounce: null,

      university_payment_form: {
        fee_type: "",
        up_id: null,
        fw_university_id: "",
        currency: "",
        label: "",
        amount: "",
        student_user_id: null,
        application_id: null,
      },
      flyware_universities: [],
      flyware_currencies: [],

      fee_type_options: [
        {
          value: "deposit",
          label: "Deposit",
        },
        {
          value: "advance_fee",
          label: "Advance Fee",
        },
        {
          value: "application_fee",
          label: "Application Fee",
        },
        {
          value: "other",
          label: "Other",
        },
      ],

      payment_status_map: {
        pending: {
          variant: "primary",
          label: "Pending",
        },
        ongoing: {
          variant: "secondary",
          label: "Ongoing",
        },
        success: {
          variant: "success",
          label: "Success",
        },
        failed: {
          label: "Failed",
          variant: "danger",
        },
        guaranteed: {
          variant: "success",
          label: "Processing",
        },
      },
    };

  },

  computed: {
    user_type() {
      let user_type = store.getters["user/getUserDetails"].user_type;

      if (["visa", "operations", "operations_manager"].includes(user_type)) {
        this.getAllFlywireUniversities();
        this.getAllFlywireCurrencies();
        this.getAllCounselors();
      }

      return user_type;
    },
  },
  methods: {
    moment,
    navigateToStudentDocument(id) {
      window.open(`/student_details/${id}?to=staffDocuments`, "__blank");
      // this.$router.push({
      //   path: `/student_details/${id}?to=staffDocuments`,
      // });
    },
    onDetailModalClose() {
      this.popupData = {};
    },

    resetUniversityPaymentForm() {
      this.university_payment_form = {
        fee_type: "",
        up_id: null,
        fw_university_id: "",
        label: "",
        amount: "",
        student_user_id: null,
        application_id: null,
      };
    },

    getCorrectFlatpickrTime(datetime) {
      return this.moment(datetime)
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .toDate();
    },

    async getAllCounselors() {
      try {
        const response = await OperationsServices.getAllCounselors();

        if (response.data.status) {
          this.counselor_options = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },

    openDetailModal(data) {
      // console.log("popupData => ",data);
      this.popupData = data;
      this.$refs.detailModal.show();
    },

    async applicationDetails(row) {
      const res = await CommonServices.getApplicationTimeline({
        student_user_id: row.student_user_id,
        sa_mapper_id: row.sa_mapper_id,
      });

      const obj = {
        ...this.applicationTimeline,
      };

      obj[row.sa_mapper_id] = res.data.data;

      this.applicationTimeline = obj;
    },
    toggleDetails(index) {
      if (this.expandedRows.includes(index)) {
        this.expandedRows = this.expandedRows.filter((i) => i !== index);
      } else {
        this.expandedRows.push(index);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getApplications();
      });
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    async getApplicationStatuses() {
      try {
        const response = await CommonServices.getAllApplicationStatuses();

        if (response.data.status) {
          this.application_statuses = response.data.data;
          this.statusFilterOptions = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
   

  

    async showApplicationStatusModal(status_id) {
      this.$refs["applicationStatusModal"].show();
      this.currentApplicationStatus = this.application_statuses.find(
        (status) => status.status_id === status_id
      );
    },

    showUniversityPaymentModal(sa_id) {
      const currentApplication = this.applicationsList.find(
        (a) => a.sa_mapper_id === sa_id
      );

      this.university_payment_form.student_user_id =
        currentApplication.student_user_id;
      this.university_payment_form.application_id =
        currentApplication.sa_mapper_id;

      if (!currentApplication) return;

      this.$refs["universityPaymentModal"].show();
    },

    async cancelPayment(up_id) {
      try {
        const res = await OperationsServices.cancelUniversityPayment(up_id);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "University Payment Cancelled",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.getApplications();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed To Cancel University Payment",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error(error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed To Cancel University Payment",
            icon: "XIcon",
            variant: "failure",
          },
        });
      }
    },

    validateUniversityPayment() {
      const {
        fw_university_id,
        fee_type,
        amount,
        student_user_id,
        application_id,
      } = this.university_payment_form;

      if (
        !fw_university_id ||
        !fee_type ||
        !amount ||
        !student_user_id ||
        !application_id
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Fill All Details",
            icon: "XIcon",
            variant: "failure",
          },
        });

        return false;
      }

      return true;
    },

    async makeUniversityPayment() {
      if (!this.validateUniversityPayment()) return;

      try {
        const res = await OperationsServices.saveUniversityPayment(
          this.university_payment_form
        );

        if (res.data.status) {
          this.$refs["universityPaymentModal"].hide();

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "University Payment Added",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.getApplications();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed to Save University Payment",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    onApplicationStatusModalClose() {
      this.$refs["applicationStatusModal"].hide();
    },

    onUniversityPaymentModalClose() {
      // this.$refs["universityPaymentModal"].hide();
      this.resetUniversityPaymentForm();
    },

    async getAllFlywireUniversities() {
      try {
        const res = await CommonServices.getAllFlywireUniversities();
        if (res.data.status) {
          // Change For Production
          this.flyware_universities = res.data.data.filter((u) =>
            u.id.startsWith("FLYWIRE:")
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getAllFlywireCurrencies() {
      try {
        const res = await CommonServices.getAllFlywireCurrencies();
        if (res.data.status) {
          this.flyware_currencies = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async onUniversitySelect() {
      // this.university_payment_form.currency =
      const res = await CommonServices.getSingleFlywireUniversity(
        this.university_payment_form.fw_university_id
      );
      if (res.data.status) {
        this.university_payment_form.currency = res.data.data.currency;
      }
    },

    getFilteredApplicationSubStatuses(status_id) {
      return this.application_sub_statuses.filter(
        (sub_status) => sub_status.status_id === status_id
      );
    },
    applicationHasSubStatus(status_id) {
      return this.application_sub_statuses.find(
        (sub_status) => sub_status.status_id === status_id
      )
        ? true
        : false;
    },
    async getApplicationSubStatuses() {
      try {
        const response = await CommonServices.getAllApplicationSubStatuses();

        if (response.data.status) {
          this.application_sub_statuses = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getAllYears,
    async getApplications() {
      const payload = {
        limit: this.pagination.perPage,
        offset: this.pagination.perPage * (this.pagination.currentPage - 1),
        status: this.filters.selectedStatus,
        intakeDate: this.filters.intakeDate,
        applicationDate: this.filters.selectedApplicationDate,
        country: this.filters.selectedCountry,
        search: this.filters.searchText,
        counselor_id: this.filters.counselor_id,
        follow_up_date: this.filters.follow_up_date,
      };

      const res = await CommonServices.getApplications(payload);
      console.log({ res });

      this.applicationsList = res.data.data.data.map((a) => ({
        ...a,
        payment_deadline_date: this.getCorrectFlatpickrTime(
          a.payment_deadline_date
        ),
      }));
      console.log({ a: this.applicationsList });

      this.totalApplications = res.data.data.count;
    },
    resetFilters() {
      this.filters = {
        searchText: "",
        selectedStatus: null,
        selectedApplicationDate: null,
        selectedCountry: null,
        intakeDate: {
          month: "",
          year: "",
        },
      };

      this.getApplications();
    },

    async getApplicationStatuses() {
      try {
        const response = await CommonServices.getAllApplicationStatuses();

        if (response.data.status) {
          this.application_statuses = response.data.data;
        }
        // Use status_key as value
      } catch (error) {
        console.error(error);
      }
    },

    async changeApplicationStatus(status_id, application_id, student_user_id) {
      this.changeApplicationSubStatus(null, application_id, student_user_id);
      try {
        const response = await OperationsServices.setApplicationStatus({
          student_user_id,
          status_id,
          application_id,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status Updated",
              icon: "Check",
              variant: "success",
            },
          });
          // this.getApplications();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Status not updated",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async changeApplicationSubStatus(
      sub_status_id,
      application_id,
      student_user_id
    ) {
      try {
        const response = await OperationsServices.setApplicationSubStatus({
          student_user_id,
          sub_status_id,
          application_id,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Sub Status Updated",
              icon: "Check",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Sub Status not updated",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async setApplicationExtraInfo(
      comment,
      application_fee,
      fee_currency,
      sa_mapper_id,
      student_user_id
    ) {
      if (this.commentsDebounce) clearTimeout(this.commentsDebounce);

      this.commentsDebounce = setTimeout(async () => {
        const response = await OperationsServices.setApplicationExtraInfo({
          student_user_id,
          comment,
          application_fee,
          fee_currency,
          application_id: sa_mapper_id,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Extra Info Updated",
              icon: "Check",
              variant: "success",
            },
          });
          this.getApplications();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Extra Info not updated",
              icon: "X",
              variant: "primary",
            },
          });
        }
      }, 1000);
    },

    async updateApplication(data) {
      try {
        const response = await OperationsServices.updateApplication({
          status_id: data.status_id,
          sub_status_id: data.sub_status_id,
          comment: data.comments,
          tags: data.tags,
          application_fee: data.application_fee,
          deposit_amount: data.deposit_amount,
          application_fee: data.application_fee,
          fee_currency: data.fee_currency,
          payment_deadline_date: data.payment_deadline_date,
          follow_up_date: data.follow_up_date,
          student_user_id: data.student_user_id,
          application_id: data.sa_mapper_id,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Application Updated",
              icon: "Check",
              variant: "success",
            },
          });
          this.getApplications();
          
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Application not updated",
              icon: "X",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.error("Update Application Failed", error);
      }
    },

    goToUniversityPage(id) {
      this.$router.push("/university-detail/" + id);
    },
  },

  beforeMount() {
    this.$store.dispatch("home/onGetCountries");
    this.getApplications();
    this.getApplicationStatuses();
    this.getApplicationSubStatuses();

    // this.getAllCounselors();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.horizontal-timeline {
  display: flex;
  flex-direction: row;
  /* Horizontal layout */
  align-items: center;
  /* Center events vertically */

  .timeline-event {
    margin: 0 10px;
    /* Adjust spacing between events */
  }
}
</style>
